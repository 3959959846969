import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";

import getMemberID from "../functions/getMemberID";
import getSubscription from "../functions/getSubscription";

import CallIcon from "@mui/icons-material/Call";
import ComputerIcon from "@mui/icons-material/Computer";
import EmailIcon from "@mui/icons-material/Email";

export default function JoinGroup({ user, showGroup, setShowGroup }) {
  const [applied, setApplied] = useState(false);
  const [application, setApplication] = useState(null);

  const newApplicationMessage =
    "Thank you for applying. The group administrator will review and contact you directly.";
  const existingApplicationMessage =
    "You have already applied for this group. Please review the information below...";

  const { symbol, decimals } = showGroup.attributes.currency.data.attributes;

  const membershipFeeDisplay = showGroup.attributes.fee
    ? `${symbol} ${showGroup.attributes.fee.toFixed(decimals)}${"  "} ( ${
        showGroup.attributes.frequency || "lifetime"
      } )`
    : "no charge";
  console.log("membershipFeeDisplay:", membershipFeeDisplay);
  const onJoin = async () => {
    setApplied(true);

    console.log("showGroup:", showGroup);
    const groupID = showGroup.id;
    console.log("groupID:", groupID);

    try {
      // retrieve member id from existing member or create new member
      const memberID = await getMemberID(user);
      console.log("memberID:", memberID);

      // retrieve existing subscription info or create new subscription
      const subscription = await getSubscription(memberID, groupID);
      console.log("subscription:", subscription);

      setApplication(subscription);
    } catch (error) {
      console.error(error);
    }

    // display subscription status and membership id
    // show actions
  };

  console.log("application:", application);

  return (
    <Grid item container direction="column" alignItems="center">
      <Grid item>
        <Typography
          paragraph
          variant="h2"
          sx={{
            fontFamily: "Plus Jakarta Sans",
            fontSize: "1.5rem",
            fontWeight: "700",
            textAlign: "center",
            color: "#fff",
          }}
        >
          {showGroup.attributes.name}
        </Typography>
      </Grid>

      {showGroup.attributes.logo?.data?.attributes?.url ? (
        <Grid
          item
          justifyContent="center"
          sx={{ backgroundColor: "#fff", opacity: "90%" }}
          marginTop={1}
          marginBottom={1}
        >
          <img
            src={showGroup.attributes.logo.data.attributes.url}
            alt={showGroup.attributes.name}
            width="150px"
          />
        </Grid>
      ) : undefined}

      <Grid item>
        <Typography
          paragraph
          variant="h2"
          sx={{
            fontFamily: "Plus Jakarta Sans",
            fontSize: "1.2rem",
            textAlign: "center",
            color: "#fff",
            mt: 2,
          }}
        >
          {showGroup.attributes.introduction ?? ""}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          paragraph
          variant="h2"
          sx={{
            fontFamily: "Plus Jakarta Sans",
            fontSize: "1rem",
            lineHeight: "130%",
            textAlign: "center",
            color: "#fff",
            mt: 2,
          }}
        >
          {showGroup.attributes.description ?? ""}
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        marginTop={2}
        marginBottom={2}
      >
        {showGroup.attributes?.telephone && (
          <Grid item>
            <Typography
              paragraph
              variant="h2"
              sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: "0.8rem",
                textAlign: "center",
                color: "#fff",
                mr: 1,
              }}
            >
              <CallIcon
                sx={{
                  fontSize: "inherit",
                  color: "inherit",
                  mr: 1,
                }}
              />{" "}
              {showGroup.attributes.telephone}
            </Typography>
          </Grid>
        )}
        {showGroup.attributes?.website && (
          <Grid item>
            <Typography
              paragraph
              variant="h2"
              sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: "0.9rem",
                textAlign: "center",
                color: "#fff",
                mr: 1,
              }}
            >
              <ComputerIcon
                sx={{
                  fontSize: "inherit",
                  color: "inherit",
                  mr: 1,
                }}
              />{" "}
              {showGroup.attributes.website}
            </Typography>
          </Grid>
        )}
        {showGroup.attributes?.email && (
          <Grid item>
            <Typography
              paragraph
              variant="h2"
              sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: "0.9rem",
                textAlign: "center",
                color: "#fff",
                mr: 1,
              }}
            >
              <EmailIcon
                sx={{
                  fontSize: "inherit",
                  color: "inherit",
                  mr: 1,
                }}
              />{" "}
              {showGroup.attributes.email}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item marginTop={3} marginBottom={2}>
        <Typography
          paragraph
          variant="h2"
          sx={{
            fontFamily: "Plus Jakarta Sans",
            fontSize: "1.2rem",
            lineHeight: "130%",
            textAlign: "center",
            color: "#fff",
          }}
        >
          Membership Fee:
          <br />
          {membershipFeeDisplay}
        </Typography>
      </Grid>

      <Grid item>
        {applied && application ? (
          <>
            <Grid item>
              <Typography
                paragraph
                variant="h2"
                sx={{
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "1rem",
                  lineHeight: "200%",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {application.status.id === 1
                  ? newApplicationMessage
                  : existingApplicationMessage}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                paragraph
                variant="h2"
                sx={{
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "1rem",
                  lineHeight: "200%",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                Application status: {application.status.name}
                <br />
                Updated: {application.createdAt.slice(0, 10)}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Button
              onClick={onJoin}
              variant="contained"
              sx={{
                border: 1,
                borderColor: "#1e293b",
                borderRadius: 3,
                backgroundColor: "#a6c2f4",
                color: "#242f41",
                width: "100%",
                "&:hover, &.Mui-focusVisible": {
                  color: "#1e293b",
                  backgroundColor: "#fff",
                  border: 1,
                  borderColor: "#1e293b",
                },
              }}
            >
              Apply to join
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
}
