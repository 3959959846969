import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import LetsGo from "../components/LetsGo";
import SelectGroup from "../components/SelectGroup";

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/plus-jakarta-sans/400.css";
import "@fontsource/plus-jakarta-sans/600.css";

export default function StartPage() {
  const [search, setSearch] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const testUser = {
    id: "438223935",
    first_name: "Mark",
    last_name: "Tanser",
    username: "Mark_T1000",
    language_code: "en",
    photo_url: "none",
  };

  const mode = process.env.REACT_APP_MODE;
  console.log("mode:", mode);

  let isDevelopment = false;
  if (mode === "development") {
    isDevelopment = true;
  }
  console.log("isDevelopment:", isDevelopment);

  let tele = null;
  let initData = "";
  let user = null;

  if (!isDevelopment) {
    tele = window.Telegram.WebApp;
    initData = new URLSearchParams(tele.initData);
    user = JSON.parse(initData.get("user"));
  } else {
    user = testUser;
  }

  console.log("user:", user);

  useEffect(() => {
    if (!isDevelopment && user) {
      tele.ready();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ backgroundColor: "#1e293b", height: "200vh" }}
        padding={1}
      >
        {!search ? (
          <LetsGo user={user} search={search} setSearch={setSearch} />
        ) : !user ? undefined : (
          <SelectGroup
            user={user}
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            setSearch={setSearch}
          />
        )}
      </Grid>
    </div>
  );
}
