import axios from "axios";

export default function getTicketsStatus(eventID) {
  return axios
    .post(
      process.env.REACT_APP_STRAPI_API_URL + "/api/tickets/get-tickets-status",
      { eventID },
      { headers: undefined }
    )
    .then((response) => {
      const { ticketsStatus } = response.data;
      console.log("ticketsStatus:", ticketsStatus);
      return ticketsStatus;
    })
    .catch((error) => {
      console.log("there was an error retrieving ticket Applications");
      console.error(error);
      return null;
    });
}
