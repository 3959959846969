import axios from "axios";

export default function getTicket(memberUuid, eventID, statusType) {
  if (memberUuid && eventID && statusType) {
    return axios
      .post(
        process.env.REACT_APP_STRAPI_API_URL + "/api/tickets/get-ticket",
        { memberUuid, eventID, statusType },
        { headers: undefined }
      )
      .then((response) => {
        const { ticket } = response.data;
        console.log("ticket", ticket);
        return ticket;
      })
      .catch((error) => {
        console.log("there was an error retrieving ticket details");
        console.error(error);
        return null;
      });
  } else {
    return null;
  }
}
