import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import StartPage from "../pages/start";
import EventPage from "../pages/event";

export const ScrollTop = ({ children, location }) => {
  useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

export default function Layout() {
  return (
    <Routes>
      <Route path="/" exact element={<StartPage />} />
      <Route path="/start" element={<StartPage />} />
      <Route path="/event" element={<EventPage />} />
    </Routes>
  );
}
