import React, { useState, useEffect } from "react";

import axios from "axios";

import { Container, Grid, Typography, Button } from "@mui/material";

import getTicket from "../functions/getTicket";
import getTicketStatus from "../functions/getTicketStatus";
import getTicketsStatus from "../functions/getTicketsStatus";

export default function EventPage() {
  const [ticket, setTicket] = useState(null);
  const [ticketStatus, setTicketStatus] = useState("none");
  const [ticketsStatus, setTicketsStatus] = useState({
    applied: 0,
    pending: 0,
    paid: 0,
  });
  const [ticketsAvailable, setTicketsAvailable] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventViewed, setEventViewed] = useState(false);
  const [eventResponded, setEventResponded] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [purchased, setPurchased] = useState(false);
  const [applied, setApplied] = useState(false);

  const [id, setId] = useState(null);
  const [memberUuid, setMemberUuid] = useState(null);
  const [articleID, setArticleID] = useState(null);

  const newApplicationMessage =
    "Thank you for applying for a ticket to this event. The group administrator will contact you on Telegram.";
  const existingApplicationMessage =
    "You have already applied for a ticket to this event.";
  const newPurchaseMessage = "Thank you. Ticket Purchased.";
  const alreadyPurchasedMessage =
    "You have already reserved a ticket for this event.";

  // Format the date
  const dateOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  // Format the time
  const timeOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  useEffect(() => {
    // Fetch event data using the 'id' from the query string
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setId(urlParams.get("id"));

    setMemberUuid(urlParams.get("member"));
    setArticleID(urlParams.get("article"));
  }, []);

  useEffect(() => {
    //check valid id value and fetch event data
    if (id && memberUuid && !selectedEvent && !eventViewed) {
      console.log("fetching data for event with id:", id);
      fetchEvent(id).then((eventData) => {
        if (eventData) {
          setSelectedEvent(eventData);
          console.log("############### setting ticketsAvailable to.... :");
          console.log(eventData.attributes.signupLimit);
          setTicketsAvailable(eventData.attributes.signupLimit);
        }
      });
      fetchTicketStatus(id, memberUuid).then((ticketStatus) => {
        if (ticketStatus) {
          setTicketStatus(ticketStatus);
        }
        console.log("ticketStatus:", ticketStatus);
      });

      fetchTicketsStatus(id).then((ticketsStatus) => {
        if (ticketsStatus) {
          setTicketsStatus(ticketsStatus);
        }
        console.log("ticketsStatus:", ticketsStatus);
      });
    } else {
      console.log("invalid event id or memberUuid");
    }
  }, [id, memberUuid]);

  // set ticketsAvailable
  useEffect(() => {
    if (ticketsStatus && selectedEvent) {
      console.log("####### selectedEvent:", selectedEvent);
      console.log("####### ticketsStatus:", ticketsStatus);

      setTicketsAvailable(
        selectedEvent.attributes?.signupLimit -
          (ticketsStatus.pending || 0 + ticketsStatus.paid || 0)
      );
    }
  }, [ticketsStatus]);

  useEffect(() => {
    if (selectedEvent && !eventViewed) {
      console.log("selectedEvent:", selectedEvent);
      const dateObj = new Date(
        selectedEvent.attributes.startDate +
          "T" +
          selectedEvent.attributes.startTime
      );
      setStartDate(dateObj.toLocaleDateString("en-US", dateOptions));
      setStartTime(dateObj.toLocaleTimeString("en-US", timeOptions));
      setEventViewed(true);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (eventViewed || eventResponded) {
      console.log("recording article recipient viewed or responded");
      console.log("articleID:", articleID);
      console.log("member uuid:", memberUuid);
      // update recipients
      if (articleID && memberUuid) {
        axios
          .post(
            `${process.env.REACT_APP_STRAPI_API_URL}/api/articles/update-recipients`,
            {
              data: {
                articleID,
                memberUUID: memberUuid,
                viewed: eventViewed,
                responded: eventResponded,
              },
            }
          )
          .then((response) => console.log("response;", response.data))
          .catch((error) => console.error(error));
      }
    }
  }, [eventViewed, eventResponded]);

  // function to fetch event data from strapi API
  const fetchEvent = async (eventId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STRAPI_API_URL}/api/events/${eventId}?populate=*`
      );
      const eventData = response.data.data;
      console.log("eventData", eventData);

      return eventData;
    } catch (error) {
      console.error("Error fetching event data:", error);
      return null;
    }
  };

  // function to fetch ticket status for member
  const fetchTicketStatus = async (eventID, memberUuid) => {
    try {
      const ticketStatus = await getTicketStatus(eventID, memberUuid);

      console.log("ticketStatus", ticketStatus);

      return ticketStatus;
    } catch (error) {
      console.error("Error fetching ticketStatus data:", error);
      return null;
    }
  };

  // function to fetch tickets status for event
  const fetchTicketsStatus = async (eventID) => {
    try {
      const ticketsStatus = await getTicketsStatus(eventID);
      console.log("ticketsStatus", ticketsStatus);

      return ticketsStatus;
    } catch (error) {
      console.error("Error fetching ticketsStatus data:", error);
      return null;
    }
  };

  const onApply = async () => {
    console.log("selectedEvent:", selectedEvent);
    let statusType;

    if (selectedEvent.attributes.requireTicketApproval) {
      setApplied(true);
      statusType = 1;
    } else {
      setPurchased(true);
      statusType = 6;
    }

    const eventID = selectedEvent.id;

    console.log("eventID:", eventID);

    try {
      // create new ticket
      const ticket = await getTicket(memberUuid, eventID, statusType);
      console.log("ticket:", ticket);

      setTicket(ticket);
      setEventResponded(true);
    } catch (error) {
      console.error(error);
    }
  };

  console.log("@@@@@ ticketsAvailable:", ticketsAvailable);
  console.log("@@@@@ ticketStatus:", ticketStatus);
  console.log("@@@@@ ticketsStatus:", ticketsStatus);
  console.log("@@@@@ applied:", applied);
  console.log("@@@@@ purchased:", purchased);
  console.log(
    "@@@@@ selectedEvent.attributes.requireTicketApproval:",
    selectedEvent?.attributes?.requireTicketApproval
  );
  console.log(
    "@@@@@ selectedEvent.attributes.fullPrice:",
    selectedEvent?.attributes?.fullPrice
  );

  return (
    <Container maxWidth="xs">
      {selectedEvent ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          marginTop={2}
          backgroundColor="#1e293b"
          paddingLeft={2}
          paddingRight={2}
        >
          <Grid item marginTop={2} marginBottom={2}>
            {/* group */}
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: "1.75rem",
                textAlign: "center",
                color: "#fff",
              }}
            >
              {selectedEvent.attributes.group?.data?.attributes.name}
            </Typography>
          </Grid>
          <Grid item>
            {/* cover image */}
            <img
              src={selectedEvent.attributes.cover?.data?.attributes.url}
              alt="event"
              width="100%"
            />
          </Grid>
          <Grid item marginTop={2}>
            {/* title */}
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: "1.375rem",
                textAlign: "center",
                color: "#fff",
              }}
            >
              {selectedEvent.attributes.title}
            </Typography>
          </Grid>

          <Grid
            container
            marginTop={2}
            direction="row"
            justifyContent="space-between"
          >
            {/* date & time */}

            <Grid item xs={6}>
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "1.2rem",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {startDate}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "1.2rem",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {startTime}
              </Typography>
            </Grid>
          </Grid>
          {selectedEvent.attributes.venue && (
            <>
              <Grid item marginTop={2}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "0.9rem",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  venue:
                </Typography>
              </Grid>
              <Grid item>
                {/* venue */}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "1rem",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {selectedEvent.attributes.venue}
                </Typography>
              </Grid>
            </>
          )}
          {selectedEvent.attributes.description && (
            <Grid item marginTop={2}>
              {/* description */}
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "1rem",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {selectedEvent.attributes.description}
              </Typography>
            </Grid>
          )}
          {selectedEvent && (
            <>
              <Grid item marginTop={2}>
                {/* prices and tickets */}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "1rem",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {selectedEvent.attributes.fullPrice === 0
                    ? "Free Event"
                    : `Ticket Price: 
                  ${
                    selectedEvent &&
                    selectedEvent.attributes.currency.data.attributes.symbol
                  }${" "}
                  ${selectedEvent.attributes.fullPrice}`}
                </Typography>
              </Grid>

              <Grid item marginTop={2}>
                {(!applied && ticketStatus === "applied") ||
                ticketStatus === "pending" ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Plus Jakarta Sans",
                        fontSize: "1rem",
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      {existingApplicationMessage}
                    </Typography>
                  </>
                ) : !purchased && ticketStatus === "paid" ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Plus Jakarta Sans",
                        fontSize: "1rem",
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      {alreadyPurchasedMessage}
                    </Typography>
                  </>
                ) : applied ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Plus Jakarta Sans",
                        fontSize: "1rem",
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      {newApplicationMessage}
                    </Typography>
                  </>
                ) : purchased ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Plus Jakarta Sans",
                        fontSize: "1rem",
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      {newPurchaseMessage}
                    </Typography>
                  </>
                ) : (
                  ticketsAvailable > 0 && (
                    <>
                      <Button
                        onClick={onApply}
                        variant="contained"
                        sx={{
                          border: 1,
                          borderColor: "#1e293b",
                          borderRadius: 3,
                          backgroundColor: "#a6c2f4",
                          color: "#242f41",
                          width: "100%",
                          "&:hover, &.Mui-focusVisible": {
                            color: "#1e293b",
                            backgroundColor: "#fff",
                            border: 1,
                            borderColor: "#1e293b",
                          },
                        }}
                      >
                        {selectedEvent.attributes.requireTicketApproval
                          ? "Apply Now"
                          : selectedEvent.attributes.fullPrice > 0
                          ? "Buy Ticket"
                          : "Reserve Ticket"}
                      </Button>
                    </>
                  )
                )}
              </Grid>
              <Grid item marginTop={2}>
                {!purchased &&
                !applied &&
                !ticketStatus &&
                ticketsAvailable > 0 ? (
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "1rem",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    {ticketsAvailable} of {selectedEvent.attributes.signupLimit}{" "}
                    tickets available.
                  </Typography>
                ) : !purchased && !applied && ticketsAvailable === 0 ? (
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "1rem",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    ( no tickets currently available )
                  </Typography>
                ) : undefined}
              </Grid>
            </>
          )}

          <Grid item marginTop={2}>
            {/* signups and availabilty */}
          </Grid>
          <Grid item marginTop={2}>
            {/* countdown timer ?? */}
          </Grid>
        </Grid>
      ) : (
        <p>Please select a valid event.</p>
      )}
    </Container>
  );
}
